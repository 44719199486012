<template>
    <div class="">
        <div class="content-logo">
            <vuexy-logo style="width:5rem;" />
        </div>
        <div>
        <b-row>
            <b-col md="12">
                <Patients-form 
                v-if="isPatientFormSidebarActive"
                :is-patient-form-sidebar-active.sync="isPatientFormSidebarActive"
                />
            </b-col>
        </b-row>
        </div>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import PatientsCard from '../../patients/patients-detail/PatientsCard.vue'
import { ref,onUnmounted } from '@vue/composition-api'
import PatientsForm from './PatientsForm.vue'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { formatDateTh,getCommaDecimal } from '@core/utils/filter'
import VuexyLogo from '@core/layouts/components/Logo.vue'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,
        VueGoodTable,
        vSelect,
        PatientsCard,
        PatientsForm,
        VuexyLogo
    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple
    },
    setup(props, { emit }) {
        const isPatientFormSidebarActive = ref(true)
        return {
            isPatientFormSidebarActive,
        }
    }
}
</script>

<style>
    body {
        overscroll-behavior-y: contain;
    }

    .content-logo {
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
</style>