<template>
    <div>
        <b-breadcrumb
        :items="breadcrumb"
      />
        <b-card no-body>
            <div class="m-2">
                <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
                <b-form
                    class="form-blue"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                <b-row>
                    <b-col lg="2">
                        <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                      >
                        <b-media-aside>
                          <div class="d-inline-block upload-img">
                            <b-img
                                ref="refPreviewEl"
                                v-if="Data.avatar"
                                :src="Data.avatar"
                                height="120"
                                width="120"
                                class="rounded-circle "
                            />
                            <b-img
                                ref="refPreviewEl"
                                v-else
                                src="@/assets/images/no-img.png"
                                height="120"
                                width="120"
                                class="rounded-circle "
                            />
                            <b-img
                                class="icon-upload"
                            src="@/assets/images/icons/upload.png"
                           
                             
                            />
                            <b-form-file
                            id="fileInput"
                                class="visuallyhidden"
                                ref="refInputEl"
                                size="sm"
                                v-model="Data.imagesFile"
                                accept=".jpg, .png, .gif"
                                
                                @input="inputImageRenderer"
                                />
                          </div>
                          <b-card-text class="my-50">
                           <!-- <small class="text-muted">Required image resolution 312x312, image size 3mb.</small> -->
                          </b-card-text>
                        </b-media-aside>
                      </b-media>
                    </b-col>
                    <b-col lg="10">
                        <b-row>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="prefix"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="คำนำหน้า"
                                    label-for="prefix"
                                    >
                                        <b-form-select
                                            :options="title_options"
                                            id="prefix"
                                            v-model="Data.prefix"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                
                                <validation-provider
                                    #default="validationContext"
                                    name="NationalIDNumber"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="เลขที่บัตรประชาชน"
                                    label-for="NationalIDNumber"
                                    >
                                        <b-form-input
                                            id="NationalIDNumber"
                                            v-model="Data.NationalIDNumber"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="gender"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="เพศ"
                                    label-for="gender"
                                    >
                                        <b-form-select
                                            :options="options_gender"
                                            id="gender"
                                            v-model="Data.gender"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="first_name"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="ชื่อ"
                                    label-for="first_name"
                                    >
                                        <b-form-input
                                            id="first_name"
                                            v-model="Data.first_name"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="last_name"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="นามสกุล"
                                    label-for="last_name"
                                    >
                                        <b-form-input
                                            id="last_name"
                                            v-model="Data.last_name"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="nick_name"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="ชื่อเล่น"
                                    label-for="nick_name"
                                    >
                                        <b-form-input
                                            id="nick_name"
                                            v-model="Data.nick_name"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="email"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="อีเมล"
                            label-for="email"
                            >
                                <b-form-input
                                    id="email"
                                    type="email"
                                    v-model="Data.email"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="age"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="เบอร์โทร"
                            label-for="phone"
                            >
                                <b-form-input
                                    id="phone"
                                    type="text"
                                    v-model="Data.phone"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="education"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="การศึกษา"
                                    label-for="education"
                                    >
                                        <b-form-input
                                            id="education"
                                            v-model="Data.education"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="career"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="อาชีพ"
                                    label-for="career"
                                    >
                                        <b-form-input
                                            id="career"
                                            v-model="Data.career"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider
                                    #default="validationContext"
                                    name="know_from"
                                    rules="min:0"
                                >
               
                                    <b-form-group
                                    label="รู้จักวีว่าคลินิคจาก"
                                    label-for="know_from"
                                    >
                                        <b-form-input
                                            id="know_from"
                                            v-model="Data.know_from"
                                            autofocus
                                            :state="getValidationState(validationContext)"
                                            trim
                                            
                                            
                                        />
     
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                          
                        </b-row>
    
                    </b-col>
    
               </b-row>
               <b-row class="pt-3">
                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="birthdate"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="วันเกิด"
                            label-for="birthdate"
                            >
                                <b-form-input
                                    id="birthdate"
                                    type="date"
                                    v-model="Data.birthdate"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="age"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="อายุ"
                            label-for="age"
                            >
                                <b-form-input
                                    id="age"
                                    type="number"
                                    v-model="Data.age"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="blood_type"
                            rules="min:0"
                        >
        
                            <b-form-group
                            v-model="Data.blood_type"
                            label="กรุ๊ปเลือด"
                            label-for="blood_type"
                            >
                                <b-form-select
                                    name="blood_type"
                                    id="blood_type"
                                    :options="blood_options"
                                    v-model="Data.blood_type"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
    
               </b-row>
               <b-row>
                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="drug_allergy"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="แพ้ยา"
                            label-for="drug_allergy"
                            >
                                <b-form-input
                                    id="drug_allergy"
                                    type="text"
                                    v-model="Data.drug_allergy"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="age"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="โรคประจำตัว"
                            label-for="medical_condition"
                            >
                                <b-form-input
                                    id="medical_condition"
                                    type="text"
                                    v-model="Data.medical_condition"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider
                            #default="validationContext"
                            name="remark"
                        >
        
                            <b-form-group
                            label="หมายเหตุ"
                            label-for="remark"
                            >
                                <b-form-input
                                    id="remark"
                                    v-model="Data.remark"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
    
               </b-row>

               <b-row>
                    <b-col lg="6">
                        <validation-provider
                            #default="validationContext"
                            name="tag_id"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="แทกลูกค้า"
                            label-for="tag_id"
                            >
                                <b-form-select
                                    :options="tags_Data"
                                    id="tag_id"
                                    type="text"
                                    v-model="Data.tag_id"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider
                            #default="validationContext"
                            name="age"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="กลุ่มลูกค้า"
                            label-for="group_id"
                            >
                                <b-form-select
                                    :options="group_Data"
                                    id="group_id"
                                    type="text"
                                    v-model="Data.group_id"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    @input="chageGroup($event)"
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                       
                        
                    </b-col>
                    <b-col lg="6">
                        <b-form-group
                            label="วันหมดอายุ"
                            label-for="expire_date"
                            >
                               
                            <b-form-datepicker
                                v-model="Data.expire_date"
                                id="datepicker-valid"
                                :state="true"
                            />
                              
                            </b-form-group>
                    </b-col>
    
               </b-row>

               <b-row>
                <b-col lg="12" class="pt-3 pb-2">
                    <label class="text-dark">ข้อมูลบริษัท</label>
                </b-col>
                
               </b-row>
               <b-row>
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="company_name"
                        >
        
                            <b-form-group
                            label="ชื่อบริษัท"
                            label-for="company_name"
                            >
                                <b-form-input
                                    id="company_name"
                                    type="text"
                                    v-model="Data.company_name"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="tax_number"
                        >
        
                            <b-form-group
                            label="เลขที่ผู้เสียภาษี"
                            label-for="tax_number"
                            >
                                <b-form-input
                                    id="tax_number"
                                    type="text"
                                    v-model="Data.tax_number"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="address"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="ที่อยู่"
                            label-for="address"
                            >
                                <b-form-input
                                    id="address"
                                    type="text"
                                    v-model="Data.address"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="sub_district"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="แขวง/ตำบล"
                            label-for="sub_district"
                            >
                                <b-form-input
                                    id="sub_district"
                                    type="text"
                                    v-model="Data.sub_district"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
    
               </b-row>
               <b-row>
                   
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="age"
                            rules="min:0"
                        >
        
                            <b-form-group
                            label="เขต/อำเภอ"
                            label-for="district"
                            >
                                <b-form-input
                                    id="district"
                                    type="text"
                                    v-model="Data.district"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="province"
                        >
        
                            <b-form-group
                            label="จังหวัด"
                            label-for="province"
                            >
                                <b-form-input
                                    id="province"
                                    type="text"
                                    v-model="Data.province"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider
                            #default="validationContext"
                            name="zip_code"
                        >
        
                            <b-form-group
                            label="รหัสไปษณีย์"
                            label-for="zip_code"
                            >
                                <b-form-input
                                    id="zip_code"
                                    type="text"
                                    v-model="Data.zip_code"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    
                                    
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
    
               </b-row>
              >
                <div class="d-flex mt-2">
                 <b-button
                   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                   variant="primary"
                   class="mr-2"
                   type="submit"
                 >
                   Submit
                 </b-button>
                 <b-button
                   v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                   type="button"
                   variant="outline-secondary"
                    :to="{ name: 'patients-read'}"
                 >
                   Cancel
                 </b-button>
               </div>
                </b-form>
                </validation-observer>
               
            </div>
           
        </b-card>
    </div>
    </template>
    <script>
    import { BBreadcrumb } from 'bootstrap-vue'
    import router from '@/router'
     import {
       BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab, BFormSelect, BFormDatepicker
     } from 'bootstrap-vue'
     //upload
     import {
      BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol
     } from 'bootstrap-vue'
    
    //validation
     import { ValidationProvider, ValidationObserver } from 'vee-validate'
     import { ref,onUnmounted } from '@vue/composition-api'
     import { required, alphaNum, email, password,confirmed } from '@validations'
     import formValidation from '@core/comp-functions/forms/form-validation'
     import Ripple from 'vue-ripple-directive'
     import store from '@/store'
 
     import userPatientsSelectList from './userPatientsSelectList'
     import patientsStoreModule from '../patientsStoreModule'

     //file
     import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
     import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import { useToast } from 'vue-toastification/composition'
    export default {
        components:{
            BBreadcrumb,
            BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
            BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,BFormDatepicker,
            ValidationProvider,
            ValidationObserver
        },
        directives: {
            Ripple,
        },
        data(){
            return {
            }
        },
        setup(){
            const toast = useToast()
            const PATIENTS_STORE_MODULE_NAME = 'app-patients';
            if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, patientsStoreModule)
            onUnmounted(() => {
                if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
            });
            const {
                fetchtag_patient,
                tags_Total,
                tags_Data,
            } = userPatientsSelectList();

            const {
                fetchgroup_patient,
                group_Total,
                group_Data,
            } = userPatientsSelectList();

            
            const userStorage = JSON.parse(localStorage.getItem('userData'));
            
            
            
            const breadcrumb = ref([{
              text: 'ลูกค้าทั้งหมด',
              to: { name: 'patients-read' }
            },
           
            {
              text: 'เพิ่มลูกค้า',
              active: true,
            },]);
            const title_options = ref([
            { value: 'คุณ', text: 'คุณ' },
            { value: 'นาย', text: 'นาย' },
            { value: 'นาง', text: 'นาง' },
            { value: 'นางสาว', text: 'นางสาว' },
            ]);

            const blood_options = ref([
            { value: 'A+', text: 'A+' },
            { value: 'A-', text: 'A-' },
            { value: 'B+', text: 'B+' },
            { value: 'B-', text: 'B-' },
            { value: 'AB+', text: 'AB+'},
            { value: 'AB-', text: 'AB-'},
            { value: 'O+', text: 'O+'},
            { value: 'O-', text: 'O-'},
            ]);
            const options_gender = ref([
                { value: null, text: 'Please select an option' },
                { value: 'male', text: 'ชาย' },
                { value: 'female', text: 'หญิง' },
                { value: 'lgbq', text: 'LGBQ' },
            ]);

            const disabled_input = true

            const blankData = {
            
               id:'',
               icon:'',
               created_by:userStorage.id,
               created_at:null,
               title: '',
               NationalIDNumber:'',
               gender:'',
               first_name:null,
               last_name:null,
               nick_name:'',
               pagesFile:null,
               branch_id:userStorage.branch_id,
               remove_image:'',
               ref:'viva',
               expire_date:null,
            
               career:'',
               education:'',
               know_from:'',

               images:require('@/assets/images/no-image.png'),
               imagesFile:null
               
              
           }

            const Data = ref(JSON.parse(JSON.stringify(blankData)))
            
            const resetData = () => {
               Data.value = JSON.parse(JSON.stringify(blankData))
            }
            if(router.currentRoute.params.id){ 
                const id = ref(router.currentRoute.params.id);
                store.dispatch('app-patients/fetchData', {id:id.value})
                .then(res => {
                    
                    Data.value  = JSON.parse(JSON.stringify(res.data.data[0]))
                    if(!Data.value.avatar){
                        Data.value.avatar = require('@/assets/images/no-image.png')
                    }
                }).catch((error) =>{
                    router.push({ name: 'patients-read'});
                toast({
                    component: ToastificationContent,
                    props: {
                    title: "Error fetch patient",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                    },
                })
            
                })
                
            }

           const onSubmit = () => {
            //uploadimage
            if(refPreviewEl.value.src){
                Data.value.files = refPreviewEl.value.src;
            }
            Data.value.ref = 'viva';
            Data.value.branch_id = userStorage.branch_id;
            Data.value.birthdate  =  Data.value.birthdate ? Data.value.birthdate :''
            Data.value.tag_id  =  Data.value.tag_id ? Data.value.tag_id :''
            Data.value.created_by = userStorage.id;
            Data.value.path = '/var/www/html/uploadfiles/uploads/viva/patients/';
            Data.value.savepath = 'uploads/viva/patients/';

            let formData = new FormData();
            for(const [key, value] of Object.entries(Data.value)) {
              formData.append(key, value);
            }
            
            if(Data.value.tag_patients){
                const tag_patients = Data.value.tag_patients
                console.log('tag_patients',tag_patients);
                formData.append('tag_id', tag_patients);
            }
            if(Data.value.group_patients){
                const group_patients = Data.value.group_patients
                console.log('group_patients',group_patients);
                formData.append('group_id', group_patients);
            }

            console.log('userStorage',userStorage);
            if(Data.value.id){

                store.dispatch('app-patients/updateData', formData)
                .then((res) => {
                   if(res.data.success){
                    const patients_id = Data.value.id;
                    console.log('patients_id',patients_id);
                    toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                   router.push({ name: 'patients-detail',params:{id:patients_id}, replace: true });
                
                   }else{
                   
                    toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               }).catch((error) =>{
                toast({
                    component: ToastificationContent,
                    props: {
                    title: "Error update",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: error,
                    },
                })
            
                })
            }else{

                console.log('add new patitents')
                store.dispatch('app-patients/addData', formData)
               .then((res) => {
                   if(res.data.success){
                    
                    const patients_id = res.data.data.insertId;

                    console.log('patients_id',patients_id);
                    toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                   router.push({ name: 'patients-detail',params:{id:patients_id}, replace: true });
                
                   }else{
                   
                    toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               })

            }
            
           }
           //uploadimage
           const refInputEl = ref(null);
            const refPreviewEl = ref(null);
            const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => { 
                    //console.log('refInputEl',refInputEl);
                    refPreviewEl.value.src = base64
            })
            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetData)

            const chageGroup = async (event)=>{
                console.log('event',event);
                
                const eventIndex = group_Data.value.findIndex(e => e.id === event)
             
                if(eventIndex > -1){
                    const group = group_Data.value[eventIndex];
                    console.log('group_Data',group);
                    if(group.expire_day > 0){
                    var now = new Date();
                    var duedate = new Date(now);
                    duedate.setDate(now.getDate() + group.expire_day);
                    Data.value.expire_date = duedate.toISOString().slice(0,10);
                }else{

                    Data.value.expire_date = ''

                }
                
                }
                
               
                

            }
            
            return {
                fetchtag_patient,
                tags_Total,
                tags_Data,
                fetchgroup_patient,
                group_Total,
                group_Data,
                Data,
                resetData,
                refFormObserver,
                getValidationState,
                resetForm,
                breadcrumb,
                title_options,
                blood_options,
                options_gender,
                disabled_input,
                onSubmit,
                inputImageRenderer,
                refInputEl,
                refPreviewEl,
                chageGroup
            }
    
        }
        
    }
    </script>